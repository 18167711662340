import React from 'react'
import {graphql} from 'gatsby'
import Header from '../layouts/header'
import Footer from '../layouts/footer'
import Canvas from '../layouts/canvas'
import Breadcrumb from '../layouts/breadcrumb'
import {Helmet} from 'react-helmet'
import Img from 'gatsby-image'
import Inparser from '../functions/inparser'



class Worktemplate extends React.Component{
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.data.drupal.nodeById.body,"body")
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen
    }
    else {
      menu = this.props.pageContext.menuel
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({url:item.entityUrl.path, id:item.entityLanguage.id})
    })

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })
    return (
      <div>
        <Helmet>
         {this.props.pageContext.metainfo.map((item,i) => {
           if(item.key === 'title') {
             return (

               [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]



             )
           }
 
           else if(item.key === 'description') {
             return (

              [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]



             )
           }

           else {
             return null;
           }
         })}
         <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />

         <meta property="og:type" content="Website" />
         <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
         <meta property="og:image" content={`${process.env.FRONT_URL}${this.props.data.drupal.nodeById.fieldDesktopImage[0].imageFile.childImageSharp.fluid.src}`} />
         <meta property="og:image:width" content="400" />
         <meta property="og:image:height" content="300" />
        </Helmet>
        <Canvas>
     <Header language={this.props.pageContext.langcode} menu={menu} langpath={langpath}/>
       <Breadcrumb title={this.props.data.drupal.nodeById.title} path={loc}/>
       <div className="container-fluid containerworks mt-5">
           <div className="row">
              <div className="col-md-9">
                  <div className="imagecont imagecontdesktop">
                    <div className="desktopscreen">
                    <div className="imagecontinnerdesk">
                   <Img  fluid={this.props.data.drupal.nodeById.fieldDesktopImage[0].imageFile.childImageSharp.fluid} alt={this.props.data.drupal.nodeById.fieldDesktopImage[0].alt}/>
                     </div>
                     </div>

                         <i className="imagescrollcont fas fa-angle-double-down animated fadeIn floating"></i>





                  </div>


              </div>
                <div className="col-md-3">
                  <div className="imagecont imagecontmobile d-none d-md-block">
                    <div className="mobilescreen">
                    <div className="imagecontinnermob">
                      <Img  fluid={this.props.data.drupal.nodeById.fieldMobileImage[0].imageFile.childImageSharp.fluid} alt={this.props.data.drupal.nodeById.fieldMobileImage[0].alt}/>
                    </div>
                    </div>
                  </div>
                </div>



           </div>
           <div className="contentcontainer mt-5  row">
               <div style={{lineHeight:1.4}} className="col-12 text-justify">
             {this.props.data.drupal.nodeById.body ?
                  <Inparser
                  body={this.props.data.drupal.nodeById.body}
                  baseurl={this.props.pageContext.baseurl}
                  fileQuery={this.props.data.drupal.fileQuery.entities}

                />: null}
                 </div>
           </div>
       </div>

       <Footer/>

     </Canvas>
     </div>
    )
  }


}
export default Worktemplate

export const query = graphql`
query($slug: String!, $langcode: DRUPAL_LanguageId!,$parsed:[DRUPAL_EntityQueryFilterConditionInput])  {
  drupal {
     nodeById(id: $slug, language: $langcode) {
       entityId
       title
       ... on DRUPAL_NodePortfolio {
         body {
           processed
         }

  

         fieldDesktopImage {
          url
          alt

          imageFile {

              childImageSharp {
                fluid(fit: COVER, maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid

                }

              }
            }
        }
        fieldMobileImage {
          url
          alt
          imageFile {
              childImageSharp {
                fluid(fit: COVER, maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
       }
     }

     fileQuery(limit: 300, filter: {conditions: $parsed, conjunction: OR}) {
      count
      entities {
        ... on DRUPAL_File {
          url
          imageFile {
                  id
                 publicURL
                 }
        }
      }
    }

   }
}
`
